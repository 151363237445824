$luminaryBoxSize: 60px;
$luminaryCenterSize: 25px;
$luminaryShadowSize: 35px;
$luminaryRaysGap: 20px;

$luminaryColor: rgba(40,40,40,0.8);

.luminary {
  width: $luminaryBoxSize;
  min-width: $luminaryBoxSize;
  height: $luminaryBoxSize;
  min-height: $luminaryBoxSize;

  display: flex;
  align-items: center;
  justify-content: center;
}

.luminary_center {
  width: $luminaryCenterSize;
  height: $luminaryCenterSize;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  overflow: hidden;
}

.luminary_center_capacity {
  width: $luminaryShadowSize;
  height: $luminaryShadowSize;
  border-radius: 100%;

  box-shadow: 0 0 2px 100px $luminaryColor;
}

.luminary_rays {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.luminary_ray {
  position: absolute;
  width: 5px;
  height: 2px;
  background-color: $luminaryColor;
}

.luminary_ray:nth-child(1) {
  transform: rotate(0deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(2) {
  transform: rotate(45deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(3) {
  transform: rotate(90deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(4) {
  transform: rotate(135deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(5) {
  transform: rotate(180deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(6) {
  transform: rotate(225deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(7) {
  transform: rotate(270deg) translateX($luminaryRaysGap);
}

.luminary_ray:nth-child(8) {
  transform: rotate(315deg) translateX($luminaryRaysGap);
}
