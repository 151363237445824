
.border {
  padding: 4px;

  box-sizing: border-box;

  outline: none;

  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  //border-radius: 15px;

}

.level_decoration {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  pointer-events: none;

  transition: opacity 0.3s ease-in-out;
  opacity: 0;

  &[data-active="true"] {
    opacity: 1;
  }
}

.percentage {
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: 19px;
}