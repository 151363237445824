
.range {
  flex-grow: 1;
}

.main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 100%;
  aspect-ratio: 10/25;
  padding: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 8px;

  box-sizing: border-box;
}

.buttons {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;
}

.button {
  width: 50%;
  aspect-ratio: 1/1;
  flex-grow: 1;

  border: 1px solid black;
  border-radius: 20px;

  font-size: 18px;
}